import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent {
    title = 'frenchki';

    constructor(public translate: TranslateService) {
        translate.addLangs(["en","sv","fi"]);
        translate.setDefaultLang('en');
        if(translate.getBrowserLang() !== undefined && (translate.getBrowserLang() == "en" || "fi" || "sv")) {
            translate.use(translate.getBrowserLang());
        }else{
            translate.use("en");
        }
    }
}

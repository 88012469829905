import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './frenchki/pages/contact.component';
import { PostComponent } from './frenchki/pages/post.component';
import { BlogComponent } from './frenchki/pages/blog.component';
import { HomeComponent } from './frenchki/pages/home.component';
import { UnderConstructionComponent } from './frenchki/pages/under-construction.component'


const routes: Routes = [
    {
      path:'',
      component: UnderConstructionComponent
    },
    {
      path:'**',
      component: UnderConstructionComponent
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

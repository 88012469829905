import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, Injector } from '@angular/core';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { FooterComponent } from './frenchki/components/footer/footer.component';
import { NavbarComponent } from './frenchki/components/navigation/navbar/navbar.component';
import { ContactComponent } from './frenchki/pages/contact.component';
import { HomeComponent } from './frenchki/pages/home.component';
import { BlogComponent } from './frenchki/pages/blog.component';
import { HeroComponent } from './frenchki/components/hero/hero.component';
import { BoxComponent } from './frenchki/components/box/box.component';
import { FigureGroupComponent } from './frenchki/components/figure-group/figure-group.component';
import { HeaderComponent } from './frenchki/components/header/header.component';
import { PostComponent } from './frenchki/pages/post.component';
import { UnderConstructionComponent } from './frenchki/pages/under-construction.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../i18n/', '.json');
}

export const firebaseConfig = environment.firebaseConfig;

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    ContactComponent,
    HomeComponent,
    BlogComponent,
    HeroComponent,
    BoxComponent,
    FigureGroupComponent,
    HeaderComponent,
    PostComponent,
    UnderConstructionComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: TranslateLoader, useClass: (createTranslateLoader),
            deps:[ HttpClient ]
        }
    })
  ],
  providers: [HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule {}
